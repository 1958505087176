// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { CustomPlugin } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { getLanguage } from '@/utilities/i18n.utilities';
import { CkEditorInstanceId } from '@/annotation/ckEditor.utilities';
import { setJournalPosition } from '@/worksheet/worksheet.actions';

export interface EditorDependencies {
  t: (label) => string;
}

export interface EditorProps {
  afterOnInit: (editor: object) => void;
  document: string;
  id: string;
  // documentChanged should be a ref in order to have the correct context when it is used with the internal props
  documentChanged: React.RefObject<(d: string, f: boolean) => Promise<object>>;
  onDestroy: () => void;
  initialLanguage: string;
  toolbar?: string[];
  plugins?: CustomPlugin[];
  domId: CkEditorInstanceId;
  shouldFocusOnInit: boolean;
}

export interface BaseEditorProps {
  afterOnInit: (editor) => any;
  canEdit: boolean;
  onDestroy: () => void;
  document: any;
  documentChanged: React.RefObject<(_document, forceSave) => any>;
  id: string;
  setupEditor?: (deps: EditorDependencies, props: EditorProps) => any;
  isCommentsExpanded?: boolean;
  toolbar?: string[];
  plugins?: CustomPlugin[];
  domId: CkEditorInstanceId;
  shouldFocusOnInit?: boolean;
}

export const BaseEditor: React.FunctionComponent<BaseEditorProps> = (props) => {
  const { id, canEdit, setupEditor, document, domId, shouldFocusOnInit = true } = props;
  const { t } = useTranslation();
  const userLanguage = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userLanguage);
  const language = getLanguage(userLanguage);
  const [isEditorLoaded, setIsEditorLoaded] = useState(null);
  const editorInstance = useRef(null);

  useEffect(() => {
    editorInstance.current = setupEditor(
      {
        t,
      },
      { ...props, domId, shouldFocusOnInit, initialLanguage: language },
    );
    setIsEditorLoaded(true);

    return () => {
      editorInstance.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (editorInstance.current) {
      editorInstance.current.syncHtml(document);
    }
  }, [document]);

  useEffect(() => {
    if (editorInstance.current) {
      editorInstance.current.syncId(id);
    }
  }, [id]);

  useEffect(() => {
    if (!isEditorLoaded) {
      return;
    }
    editorInstance.current.init(canEdit);
  }, [canEdit, isEditorLoaded]);

  if (!isEditorLoaded) {
    return null;
  }

  editorInstance.current.setLanguage(language);

  return (
    <>
      <div id={`${domId}ToolbarContainer`} className="editorToolbarContainer" />
      <div
        className="editorContainer"
        onScroll={() => {
          if (domId === 'journalEditor') {
            setJournalPosition({
              path: [0, 0],
              offset: window.document.querySelector('.editorContainer').scrollTop,
            });
          }
        }}>
        <div id={domId} data-testid="journalEditor" />
      </div>
    </>
  );
};
